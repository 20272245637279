import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const NoInternetConnection = (props) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return props.children;
  } else {
    return (
      <motion.div
        transition={{ duration: 0.8, delay: 0.2 }}
        initial={{ opacity: 0, x: 40 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 40 }}
        className="flex flex-col gap-4 items-center max-w-72 mx-auto justify-center h-[100vh]"
      >
        <svg
          width="181"
          height="177"
          viewBox="0 0 181 177"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M144.44 136.74C142.303 136.74 140.252 137.705 139.013 139.389C128.246 154.011 110.535 163.561 90.5175 163.561C70.4996 163.561 52.7891 153.991 42.0217 139.389C40.7826 137.705 38.7317 136.74 36.5954 136.74C31.1476 136.74 27.9002 142.675 31.0407 146.947C44.2435 164.834 65.9491 176.499 90.4961 176.499C115.043 176.499 136.749 164.834 149.952 146.947C153.135 142.675 149.887 136.74 144.44 136.74Z"
            fill="#4B108D"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M77.9509 0.975342C74.4831 0.975342 71.6719 3.78657 71.6719 7.25441V40.16C64.1205 32.7527 53.7739 28.1849 42.3605 28.1849C19.2416 28.1849 0.5 46.9265 0.5 70.0454C0.5 93.1643 19.2416 111.906 42.3605 111.906C64.3125 111.906 82.3179 95.0084 84.0795 73.5107C84.1781 73.0689 84.23 72.6096 84.23 72.1381V7.25441C84.23 3.78658 81.4188 0.975342 77.9509 0.975342ZM71.6719 69.784V70.3074C71.5313 86.3702 58.4666 99.3481 42.3707 99.3481C26.1875 99.3481 13.0684 86.229 13.0684 70.0457C13.0684 53.8625 26.1875 40.7434 42.3707 40.7434C58.4666 40.7434 71.5313 53.7213 71.6719 69.784Z"
            fill="#4B108D"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M96.7881 67.951C96.7881 44.8321 115.53 26.0906 138.649 26.0906C159.461 26.0906 176.726 41.2796 179.964 61.1774C180.322 61.9668 180.521 62.8434 180.521 63.7665V105.627C180.521 109.095 177.709 111.906 174.242 111.906C170.774 111.906 167.963 109.095 167.963 105.627V97.8339C160.411 105.243 150.063 109.812 138.649 109.812C115.53 109.812 96.7881 91.0699 96.7881 67.951ZM167.963 67.4859V68.4171C167.714 84.3856 154.692 97.2538 138.664 97.2538C122.481 97.2538 109.362 84.1347 109.362 67.9515C109.362 51.7683 122.481 38.6492 138.664 38.6492C154.692 38.6492 167.714 51.5174 167.963 67.4859Z"
            fill="#4B108D"
          />
        </svg>

        <p className="text-center text-[#8292A1]">
          No internet connection , please check your Internet connecton
        </p>
      </motion.div>
    );
  }
};

export default NoInternetConnection;
