import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    if (error.response && error.response.status === 500) {
      return { hasError: true, errorCode: 500 };
    }
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col justify-center items-center h-screen bg-red-100 text-red-800 text-center p-4">
          {this.state.errorCode === 500 ? (
            <>
              <img src="/no-authorization.svg" className="w-32 sm:w-48" />

              <h1 className="text-3xl font-bold mb-4">Server Error (500)</h1>
              <p className="text-lg">
                Something went wrong on the server. Please try again later.
              </p>
            </>
          ) : (
            <>
              <img src="/no-authorization.svg" className="w-32 sm:w-48" />

              <h1 className="text-3xl font-bold mb-4">Something went wrong.</h1>
              <p className="text-lg">Please try again later.</p>
            </>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
