import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./app/store";
import { Provider } from "react-redux";
import SuspenseContent from "./containers/SuspenseContent";
import { NextUIProvider } from "@nextui-org/react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import NoInternetConnection from "./pages/NoInternetConnection";
import ErrorBoundary from "./pages/ErrorBoundary ";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <NoInternetConnection>
    <I18nextProvider i18n={i18n}>
      <ErrorBoundary>
        {" "}
        {/* Wrap your app in ErrorBoundary */}
        <Suspense fallback={<SuspenseContent />}>
          <NextUIProvider>
            <Provider store={store}>
              <App />
            </Provider>
          </NextUIProvider>
        </Suspense>
      </ErrorBoundary>
    </I18nextProvider>
  </NoInternetConnection>
);

reportWebVitals();
