import React, { useEffect, lazy, Suspense } from "react";
import "./App.css";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import checkAuth from "./app/auth";
import initializeApp from "./app/init";
import "react-image-crop/dist/ReactCrop.css"; // needed for the crop uploaded img functionalities
import { Spinner } from "@nextui-org/spinner";

// Initializing different libraries
initializeApp();

// Check for login and initialize axios
const token = checkAuth();

// Lazy loading for pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Categories = lazy(() =>
  import("./features/brandcategories/components/categories")
);

// const Service = lazy(() => import("./features/services/component/service"));
const Create = lazy(() =>
  import("./features/services/component/create/create")
);
const Category = lazy(() => import("./features/category/cat"));
const Settings = lazy(() => import("./features/settings/index"));
const Ticket = lazy(() => import("./features/settings/component/ticket"));
const Coupon = lazy(() => import("./features/settings/component/coupon"));
const Not = lazy(() => import("./features/settings/component/not"));

const Inchat = lazy(() => import("./features/chat/component/inchat"));
const BrandDet = lazy(() =>
  import("./features/accounts/component/brandDetails")
);
const TransactionDetails = lazy(() =>
  import("./features/dashboard/components/transactionDetailss")
);

function App() {
  useEffect(() => {
    themeChange(false);
  }, []);

  return (
    <Router>
      <Suspense
        fallback={
          <div className="w-full h-screen flex justify-center items-center">
            <Spinner
              color="#4b108d"
              style={{ width: "180px", height: "180px" }}
            />
          </div>
        }
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/categories"
            element={
              <Layout>
                <Categories />
              </Layout>
            }
          />
          {/* <Route
          path="/service"
          element={
            <Layout>
              <Service />
            </Layout>
          }
        /> */}
          <Route
            path="/transactionDetailss/:id"
            element={<TransactionDetails />}
          />

          <Route
            path="/create"
            element={
              <Layout>
                <Create />
              </Layout>
            }
          />
          <Route
            path="/category"
            element={
              <Layout>
                <Category />
              </Layout>
            }
          />
          <Route
            path="/settings"
            element={
              <Layout>
                <Settings />
              </Layout>
            }
          />

          <Route
            path="/ticket"
            element={
              <Layout>
                <Ticket />
              </Layout>
            }
          />
          <Route
            path="/not"
            element={
              <Layout>
                <Not />
              </Layout>
            }
          />
          <Route
            path="/coupon"
            element={
              <Layout>
                <Coupon />
              </Layout>
            }
          />

          <Route
            path="/inchat"
            element={
              <Layout>
                <Inchat />
              </Layout>
            }
          />
          <Route
            path="/brandDet"
            element={
              <Layout>
                <BrandDet />
              </Layout>
            }
          />
          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          <Route
            path="*"
            element={<Navigate to={token ? "/login" : "/login"} replace />}
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
